import * as React from "react";
import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ArrowExternal from "../../images/arrow-external.png";
import $ from "jquery";
import eventList, {Event, LinkedEvent} from "./eventList";

class Content extends Component {
  componentDidMount() {
    var hash = window.location.hash;
    if (hash !== "#join-our-programs") return;
    var position = $(hash).offset().top;
    setTimeout(function () {
      let isMobile = $(window).width() <= 767;
      let offset = isMobile ? 110 : 35;
      let scrollValue = position - offset;
      window.scrollTo({
        top: scrollValue,
        behavior: "smooth",
      });
    }, 100);
  }
  render() {
    return (
      <div className={`${this.props.show ? "d-block" : "d-none"}`}>
        <Container
          fluid
          className="hero events"
          role="img"
          aria-label="Group of people standing
                      outside in a city"
        />
        <Container className="care-continuum">
          <Row>
            <Col xs={9} lg={10} className="m-auto px-0">
              <div className="hero-callout muted-yellow-dark">
                
                <h1 className="title">
                  <span className="sub-title d-block">Events</span>
                  Working side-by-side with the community
                </h1>
              </div>
              <Container>
                <Row>
                  <Col xs={12} className="px-0">
                    <p>
                      For more than 30 years, we have worked with the
                      community toward our collective, common goal of helping
                      end the HIV epidemic for everyone, everywhere. Through working 
                      together with the community, we are supporting the innovative work of
                      grassroots organizations to help improve access to care by
                      eliminating barriers and advancing education.
                    </p>
                    <h2 id="join-our-programs" className="title">
                      Join Our Programs & Events IN THE COMMUNITY
                    </h2>
                  </Col>
                  <Container>
                     <Row className="event-cards">
                        {eventList && eventList.map((event, i) => {
                           return (
                              <Col lg={4} className="event-card the-naesm-national mb-4" key={i}>
                                 { <Event event={event}/> }
                              </Col>
                           )
                        })}

                        {/* in order to fit the content without breaking text readers */}
                        {
                           eventList && Number.isInteger((eventList.length + 1) / 3) &&
                              <Col lg={4} key={'9999'} aria-hidden="true" className="event-card hidden">
                                 {""}
                              </Col>
                        }
                     </Row>
                  </Container>
                  <Col xs={12} className="px-0 mt-4 pt-4 mb-4 pb-3">
                    <h2 className="title">AIDS WALKS SPONSORSHIPS</h2>
                    <p>
                      For decades, community activists have organized AIDS
                      Walks, inviting people from all over the world and all
                      walks of life to join in solidarity. AIDS Walks not only
                      honor and remember those who have lost their lives to
                      AIDS, but also raise awareness and gather resources needed
                      to continue progress and help save lives. Supporting AIDS
                      Walk events is a necessary part of our role in helping end
                      the HIV epidemic, as these events bring the community
                      together, on the ground, renewing commitment to our
                      collective mission. Connect with us on{" "}
                      <a
                        class="social-link"
                        href="https://www.instagram.com/gileadhivus/"
                        target="_blank"
                        rel="nofollow noopener"
                      >
                        Instagram
                      </a>{" "}
                      and{" "}
                      <a
                        class="social-link"
                        href="https://www.facebook.com/GileadHIVUS/"
                        target="_blank"
                        rel="nofollow noopener"
                      >
                        Facebook
                      </a>{" "}
                      for updates on AIDS Walks.
                    </p>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Content;
