import React from "react";
import ArrowExternal from "../../images/arrow-external.png";

// events

// in all the tags html embebed can be used

// date: date format MM/DD/YYYY,
// title: card title,
// description: {
//   info: main info of the card,
//   location: location of the event,
// },
// detailedDate: date in letters if it is needed
// eventLink: in case that the event has a link to redirect
// eventLinkDescription: link description for the url,


const events = [
  /** 
  {
    date: "6/22/2024",
    title: `Press Play on Knowing Your HIV Status: Orlando, FL`,
    description: {
      info: `Gilead is proud to sponsor this National HIV Testing Day event. Free HIV testing will be offered by local community organizations as well as live entertainment, giveaways, and vendors.`,
      location: `
        Consulado de México 
        <span class="d-block">2550 Technology Drive </span>
        <span class="d-block">Orlando, FL 32804 </span>
      `,
    },
    detailedDate: "June 22, 10 <small>AM</small> - 2:30 <small>PM</small> ET",
  },
  {
    date: "8/3/2024",
    title: `National Medical Association (NMA) 2024 Annual Convention & Scientific Assembly `,
    description: {
      info: `Join more than 2,500 leading African American physicians, physician executives, health professionals, academicians, and scientists in over 26 specialties from across the country at the NMA 2024 Annual Convention & Scientific Assembly.`,
    },
    eventLink: "https://convention.nmanet.org/",
    eventLinkDescription: "Learn More",
  },*/

  {
    date: "12/1/2024",
    title: `World AIDS Day`,
    description: {
      info: `Join Gilead in recognizing World AIDS Day on December 1 each year. Download our free social media toolkit to acknowledge all those who have been lost to <nobr>AIDS-related</nobr> illnesses and all those impacted by HIV.`,
    },
    eventLink: "/community-involvement/social-toolkit/",
    eventLinkDescription: "Download",
  }
];

const eventList = [
  ...events.sort((a, b) => new Date(a.date) - new Date(b.date)),
];


export const EventCard = ({ event }) => {
  return (
    <>
      <span className="date">{event.date}</span>
      <span
        className="title"
        dangerouslySetInnerHTML={{ __html: event.title }}
      ></span>
      <span className="description">
        <span
          dangerouslySetInnerHTML={{ __html: event.description.info }}
        ></span>
        {event.description.location && (
          <span
            className="d-block py-3"
            dangerouslySetInnerHTML={{ __html: event.description.location }}
          ></span>
        )}
        {event.detailedDate && (
          <span dangerouslySetInnerHTML={{ __html: event.detailedDate }}></span>
        )}
      </span>
    </>
  );
};

export const Event = ({ event }) => {
  return (
    event.eventLink ?
      <>
        <a href={event.eventLink} /**target="_blank" */>
          <EventCard event={event}/>
          <span className="register button secondary large arrow">
            {event.eventLinkDescription}
          </span>
        </a>
      </>
    : <EventCard event={event}/>
  );
};

export default eventList;
